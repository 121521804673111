<script setup>
import linkSVG from "@/assets/svg/external-link-line.svg";

defineProps({ isLoading: { type: Boolean, required: false, default: false } });
const emit = defineEmits(["on-join", "on-new-game"]);

function requestNewGame() {
  emit("on-new-game");
}

function joinGame() {
  emit("on-join");
}
</script>
<template>
  <div class="card has-background-grey-darker game-setup">
    <div class="card-image"></div>
    <header class="card-header">
      <div class="card-header-title">
        <h4 class="title is-3 has-text-white">Willkommen bei Life Goes On</h4>
      </div>
    </header>
    <div class="card-content has-text-white">
      <div class="block">
        <div class="field is-grouped is-grouped-multiline">
          <div class="control">
            <div class="tags has-addons">
              <span class="tag is-warning">Spieler:innen</span>
              <span class="tag is-warning is-light">2-4</span>
            </div>
          </div>
          <div class="control">
            <div class="tags has-addons">
              <span class="tag is-warning">Dauer</span>
              <span class="tag is-warning is-light">ca. 120 min.</span>
            </div>
          </div>
          <div class="control">
            <div class="tags">
              <span class="tag is-warning">kooperatives Multimedia-Brettspiel</span>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        Die
        <i>Bundesanstalt für Einheitsbewältigung</i>
        sendet euch für einen besonderen Auftrag in die Kleinstadt Prückwitz. Mit
        Hilfe modernster Behördentechnologie hört ihr in die Vergangenheit und folgt
        den Spuren der deutsch-deutschen Geschichte. Könnt ihr die Altlasten
        ausgraben, die die Vergangenheit in der Gegenwart hinterlassen hat?
      </div>
      <div class="block">
        <h4 class="title is-4 has-text-white mb-3">benötigtes Material</h4>
        <ul class="list has-dot">
          <li>
            ausgedruckt: Spielplan in
            <a
              class="has-text-warning-light"
              href="https://livingroom-server.machinaex.org/Development/files/print/LifeGoesOn-Spielplan-Farbe.pdf"
              target="_blank"
            >
              <span class="icon-text">
                <span class="is-underlined">Farbe</span>

                <span class="icon">
                  <img class="image is-24x24" :src="linkSVG" alt="Link" />
                </span>
              </span>
            </a>
            oder
            <a
              class="has-text-warning-light"
              href="https://livingroom-server.machinaex.org/Development/files/print/LifeGoesOn-Spielplan-SW.pdf"
              target="_blank"
            >
              <span class="icon-text">
                <span class="is-underlined">Schwarz-Weiß</span>

                <span class="icon">
                  <img class="image is-24x24" :src="linkSVG" alt="Link" />
                </span>
              </span>
            </a>
          </li>
          <li>
            ausgedruckt:
            <a
              class="has-text-warning-light"
              href="https://livingroom-server.machinaex.org/Development/files/print/LifeGoesOn-Dokumente.pdf"
              target="_blank"
            >
              <span class="icon-text">
                <span class="is-underlined">Spieldokumente</span>

                <span class="icon">
                  <img class="image is-24x24" :src="linkSVG" alt="Link" />
                </span>
              </span>
            </a>
          </li>
          <li>Klebeband und Schere</li>
          <li>
            ein Tablet (oder bspw. ein Laptop), auf dem ihr das Spiel gemeinsam
            spielt
          </li>
          <li>pro Spieler:in ein Smartphone mit Kopfhörern</li>
        </ul>
      </div>
      <div class="block mb-5">
        <h4 class="title is-4 has-text-white mb-3">Anleitung</h4>
        <ol class="list">
          <li>
            Druckt den Spielplan in
            <a
              class="has-text-warning-light"
              href="https://livingroom-server.machinaex.org/Development/files/print/LifeGoesOn-Spielplan-Farbe.pdf"
              target="_blank"
            >
              <span class="icon-text">
                <span class="is-underlined">Farbe</span>

                <span class="icon">
                  <img class="image is-24x24" :src="linkSVG" alt="Link" />
                </span>
              </span>
            </a>
            oder in
            <a
              class="has-text-warning-light"
              href="https://livingroom-server.machinaex.org/Development/files/print/LifeGoesOn-Spielplan-SW.pdf"
              target="_blank"
            >
              <span class="icon-text">
                <span class="is-underlined">Schwarz-Weiß</span>

                <span class="icon">
                  <img class="image is-24x24" :src="linkSVG" alt="Link" />
                </span>
              </span>
            </a>
            aus.
          </li>
          <li>
            Folgt den Anweisungen auf
            <span>Seite 1</span>
            , um den Spielplan zusammenzufügen.
          </li>
          <li>
            Druckt die
            <a
              class="has-text-warning-light"
              href="https://livingroom-server.machinaex.org/Development/files/print/LifeGoesOn-Dokumente.pdf"
              target="_blank"
            >
              <span class="icon-text">
                <span class="is-underlined">Spieldokumente</span>

                <span class="icon">
                  <img class="image is-24x24" :src="linkSVG" alt="Link" />
                </span>
              </span>
            </a>
            entweder in Farbe (empfohlen) oder in Schwarz-Weiß aus.
          </li>
          <li>
            Folgt den Anweisungen auf
            <span>Seite 1</span>
            der Spieldokumente.
          </li>
          <li>Smartphones und Kopfhörer aller Spieler:innen bereitlegen.</li>
          <li>
            Klickt auf
            <i>Neues Spiel erstellen</i>
            , wenn ihr das Spiel auf dem gemeinsamen Gerät (Tablet o.ä.) spielen wollt, das ihr gerade
            verwendet.
          </li>
        </ol>
        <p class="mt-2">
          Wenn ihr Hilfe benötigt, meldet euch unter:
          <a class="has-text-warning" href="mailto:lifegoeson@machinaex.de">
            lifegoeson@machinaex.de
          </a>
        </p>
      </div>
      <div class="block">
        <h4 class="title is-4 has-text-white mb-3">Bereit?</h4>
        <div class="field is-grouped">
          <p class="control">
            <button
              class="button is-warning"
              :class="{ 'is-loading': isLoading }"
              @click="requestNewGame()"
            >
              Neues Spiel erstellen
            </button>
          </p>
          <p class="control">
            <button
              class="button is-warning is-light"
              :disabled="isLoading"
              @click="joinGame()"
            >
              Spiel fortsetzen
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
