<script setup>
import trashSVG from "@/assets/svg/trash.svg";
import qrSVG from "@/assets/svg/qr-code.svg";

defineProps({
  team: { type: Object, required: true },
  PLAYER: { type: Array, required: true },
  isLoading: { type: Boolean, required: true },
});
const emit = defineEmits(["on-next", "on-delete-player", "start-game"]);

function deletePlayer(player) {
  emit("on-delete-player", player);
}

function startGame() {
  emit("start-game");
}

function goBack() {
  emit("on-go-back");
}

// function goNext() {
//   emit("on-next");
// }
</script>
<template>
  <div class="card has-background-grey-darker">
    <header class="card-header">
      <div class="card-header-title">
        <div>
          <h3 class="title is-3 has-text-white">Spieler:innen Anmelden</h3>
        </div>
      </div>
    </header>
    <div class="card-content has-text-white">
      <div class="block is-relative">
        <img id="qr-code" class="image" :src="qrSVG" alt="QR Code Link" />
        <p class="pb-2">Bitte öffnet die folgende URL auf euren Smartphones:</p>
        <p class="title is-4 has-text-warning">
          https://lifegoesonplayer.machinaex.org
        </p>
      </div>
      <div class="block">
        <p class="pb-2">
          Gebt diesen Code auf euren Smartphones ein, um dem Spiel beizutreten:
        </p>
        <p class="title is-4 has-text-warning">{{ team.id }}</p>
      </div>
      <div class="block">
        <h5 class="title is-4 has-text-white mb-3">Verbundene Spieler:innen</h5>
        <div v-if="PLAYER.length > 0" class="player-list">
          <TransitionGroup name="list" tag="ul">
            <li v-for="p in PLAYER" :key="team.id + 'pl' + p.name">
              <span class="is-impact-label is-size-5 mr-3">
                {{ p.name }}
              </span>
              <span
                class="icon has-background-danger is-pulled-right is-clickable"
                @click="deletePlayer(p.name)"
              >
                <img :src="trashSVG" alt="Löschen" />
              </span>
            </li>
          </TransitionGroup>
        </div>
        <div v-else>-</div>
      </div>
    </div>
    <footer class="card-footer">
      <div class="card-footer-item is-flex-direction-column">
        <button
          class="button is-warning"
          :class="{ 'is-loading': isLoading }"
          :disabled="isLoading || PLAYER.length < 2"
          @click="startGame"
        >
          Los gehts
        </button>
        <p v-if="PLAYER.length < 2" class="help has-text-warning">
          Es müssen mindestens 2 Spieler:innen verbunden sein.
        </p>
      </div>
      <div class="card-footer-item">
        <button class="button is-warning is-light" @click="goBack()">
          Zurück zur Anleitung
        </button>
      </div>
    </footer>
  </div>
</template>
