<script setup>
import trashSVG from "@/assets/svg/trash.svg";
import qrSVG from "@/assets/svg/qr-code.svg";

defineProps({
  TEAM: { type: String, required: true },
  PLAYER: { type: Array, required: true },
});
const emit = defineEmits(["on-next", "on-delete-player"]);

function deletePlayer(player) {
  emit("on-delete-player", player);
}
</script>
<template>
  <div class="card has-background-grey-darker">
    <header class="card-header">
      <div class="card-header-title">
        <div>
          <h3 class="title is-3 has-text-white">Spieler:innen</h3>
        </div>
      </div>
    </header>
    <div class="card-content has-text-white">
      <div class="block is-relative">
        <img id="qr-code" class="image" :src="qrSVG" alt="QR Code Link" />
        <p class="pb-2">Bitte öffnet die folgende URL auf euren Smartphones:</p>
        <p class="title is-4 has-text-warning">
          https://lifegoesonplayer.machinaex.org
        </p>
      </div>
      <div class="block">
        <p class="pb-2">
          Gebt diesen Code auf euren Smartphones ein, um dem Spiel beizutreten:
        </p>
        <p class="title is-4 has-text-warning">{{ TEAM }}</p>
      </div>
      <div class="block">
        <h5 class="title is-5 has-text-white mb-3">verbundene Spieler:innen</h5>
        <div v-if="PLAYER.length > 0" class="player-list">
          <TransitionGroup name="list" tag="ul">
            <li v-for="p in PLAYER" :key="TEAM + 'pl' + p.name">
              <span class="is-impact-label is-size-5 mr-3">
                {{ p.name }}
              </span>
              <span
                class="icon has-background-danger is-pulled-right"
                @click="deletePlayer(p.name)"
              >
                <img :src="trashSVG" alt="Löschen" />
              </span>
            </li>
          </TransitionGroup>
        </div>
      </div>
    </div>
  </div>
</template>
