<script setup>
import { ref } from "vue";
import InstructionsScreen from "@/components/SettingsScreens/Instructions.vue";
import GameSetup from "@/components/SettingsScreens/GameSetup.vue";
import FAQScreen from "@/components/SettingsScreens/FAQ.vue";
import LogoutScreen from "@/components/SettingsScreens/Logout.vue";

const props = defineProps({
  BASE_URL: { type: String, required: true },
  GAME: { type: String, required: true },
  TEAM: { type: String, required: true },
  PLAYER: { type: Array, required: true },
});
const emit = defineEmits(["change-team", "logout", "close"]);

const INSTRUCTIONS = 0,
  SETUP = 1,
  FAQ = 2,
  LOGOUT = 3;

const settingsTab = ref(SETUP);

function deletePlayer(player) {
  const url = `${props.BASE_URL}/${props.GAME}/livingroom/team/${props.TEAM}/player/${player}`;
  const data = JSON.stringify({ team: props.TEAM, player: player });
  console.log(url, data);
  fetch(url, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: data,
  })
    .then((res) => {
      console.log("got data from adaptor for delete player", res.status);
    })
    .catch((error) => {
      console.error(error);
    });
}
</script>

<template>
  <Transition name="fade">
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div
          class="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between mx-3 mb-2"
        >
          <ul class="is-flex is-flex-direction-row">
            <li>
              <a
                :class="[
                  settingsTab === INSTRUCTIONS
                    ? 'has-text-warning'
                    : 'has-text-white',
                ]"
                @click="settingsTab = INSTRUCTIONS"
              >
                Anleitung
              </a>
            </li>
            <li>
              <a
                class="ml-3"
                :class="[
                  settingsTab === SETUP ? 'has-text-warning' : 'has-text-white',
                ]"
                @click="settingsTab = SETUP"
              >
                Game Setup
              </a>
            </li>
            <!-- <li class="ml-3">
              <a
                :class="[
                  settingsTab === FAQ ? 'has-text-warning' : 'has-text-white',
                ]"
                @click="settingsTab = FAQ"
              >
                FAQ
              </a>
            </li> -->
            <li class="ml-3">
              <a
                :class="[
                  settingsTab === LOGOUT ? 'has-text-warning' : 'has-text-white',
                ]"
                @click="settingsTab = LOGOUT"
              >
                Logout
              </a>
            </li>
          </ul>
          <button class="button is-warning" @click="emit('close')">Zurück</button>
        </div>
        <Transition name="quick-fade" mode="out-in">
          <InstructionsScreen v-if="settingsTab == INSTRUCTIONS" />
          <GameSetup
            v-else-if="settingsTab == SETUP"
            v-bind="{ PLAYER, TEAM }"
            @on-delete-player="deletePlayer"
          />
          <FAQScreen v-else-if="settingsTab == FAQ" />
          <LogoutScreen
            v-else-if="settingsTab == LOGOUT"
            v-bind="{ TEAM }"
            @on-close="emit('close')"
            @on-request-logout="
              emit('close');
              emit('logout');
            "
          />
        </Transition>
      </div>
    </div>
  </Transition>
</template>
