<script setup>
import { inject, ref } from "vue";
import { hasKey, getLocalValueOrDefault } from "@/utils";
import StartScreen from "@/components/LoginScreens/StartScreen.vue";
import JoinScreen from "@/components/LoginScreens/JoinScreen.vue";
// import NewGameScreen from "@/components/LoginScreens/NewGameScreen.vue";
import WaitScreen from "@/components/LoginScreens/WaitScreen.vue";

const props = defineProps({
  BASE_URL: { type: String, required: true },
  GAME: { type: String, required: true },
  TEAM: { type: String, required: true },
  PLAYER: { type: Array, required: true },
});
const emit = defineEmits(["logged-in"]);

defineExpose({ reset });
const socket = inject("socket");

const START = "START",
  JOIN = "JOIN",
  // NEWGAME = "NEWGAME",
  WAIT = "WAIT",
  DONE = "DONE";

const loginStep = ref(getLocalValueOrDefault("LOGINSTEP", "START"));
const isLoading = ref(false);
const hasError = ref("");
const team = ref({ id: props.TEAM, name: "" });

function reset() {
  isLoading.value = false;
  hasError.value = "";
  team.value = { id: "", name: "", player: [] };
}

function createNewGame() {
  if (team.value.id && team.value.name) {
    isLoading.value = false;
    socket.emit("joinTeam", team.value.id);
    nextStep(WAIT);
  } else {
    fetch(`${props.BASE_URL}/${props.GAME}/livingroom/team`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then(({ body }) => {
        console.log("got data from adaptor for new team", body);
        team.value = { id: body.id, name: body.name, player: [] };
        isLoading.value = false;
        socket.emit("joinTeam", { team: team.value.id });
        localStorage.setItem("TEAM", team.value.id);
        nextStep(WAIT);
      })
      .catch((error) => {
        isLoading.value = false;
        console.error(error);
      });
  }
}

function deletePlayer(player) {
  const url = `${props.BASE_URL}/${props.GAME}/livingroom/team/${team.value.id}/player/${player}`;
  const data = JSON.stringify({ team: team.value.id, player: player });
  console.log(url, data);
  fetch(url, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: data,
  })
    .then((res) => {
      console.log("got data from adaptor for delete player", res.status);
    })
    .catch((error) => {
      console.error(error);
    });
}

function tryJoin(code) {
  isLoading.value = true;
  fetch(`${props.BASE_URL}/${props.GAME}/livingroom/team/${code}`, { method: "GET" })
    .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
    .then(({ status, body }) => {
      console.log("got data from adaptor for join team", status, body);
      if (status === 200) {
        team.value = { id: body.id, name: body.name, player: [] };
        isLoading.value = false;
        socket.emit("joinTeam", { team: team.value.id });
        localStorage.setItem("TEAM", team.value.id);
        nextStep(DONE);
        emit("logged-in", team.value);
      } else {
        isLoading.value = false;
        hasError.value = `Ungültiger Zugangscode. Das Spiel mit dem Code ${code} konnte nicht gefunden werden.`;
      }
    })
    .catch((error) => {
      isLoading.value = false;
      hasError.value = error.toString();
      console.error(error);
    });
}

function startGame() {
  socket.on("control", (data) => {
    if (hasKey(data, "payload.game")) {
      console.log("got control game", data.payload.game);
      if (data.payload.game === "RUNNING") {
        isLoading.value = false;
        emit("logged-in", team.value);
      }
    }
  });
  isLoading.value = true;
  socket.emit("control", "startGame");
  setTimeout(() => (isLoading.value = false), 3000);
}

function nextStep(step) {
  loginStep.value = step;
  localStorage.setItem("LOGINSTEP", loginStep.value);
}
</script>

<template>
  <Transition name="fade">
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-content">
        <Transition name="quick-fade" mode="out-in">
          <StartScreen
            v-if="loginStep == START"
            v-bind="{ isLoading }"
            @on-join="nextStep(JOIN)"
            @on-new-game="createNewGame"
          />
          <JoinScreen
            v-else-if="loginStep == JOIN"
            v-bind="{ team, isLoading, hasError }"
            @on-go-back="nextStep(START)"
            @on-join="tryJoin"
          />
          <!-- <NewGameScreen
            v-else-if="loginStep == NEWGAME"
            v-bind="{ team }"
            @on-go-back="nextStep(START)"
            @on-next="nextStep(WAIT)"
          /> -->
          <WaitScreen
            v-else-if="loginStep == WAIT"
            v-bind="{ PLAYER, team, isLoading }"
            @on-delete-player="deletePlayer"
            @on-go-back="nextStep(START)"
            @start-game="startGame"
          />
        </Transition>
      </div>
    </div>
  </Transition>
</template>
